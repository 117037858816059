import React from 'react';
import Helmet from 'react-helmet';

const SEO_DATA = {
  description: 'collaboration software for creators. crm for teams of any size',
  title: 'rocketbatch',
  url: 'https://rocketbatch.com',
  author: 'timo reichen',
  keywords: ['crm', 'collaboration', 'communication', 'team software', 'messenger', 'relationships', 'projects', 'leads', 'mailclient', 'tasks', 'appointments', 'notes', 'pim', 'contacts', 'projectmanagement', 'personal kanban',],
  twitter: {
    id: '@rocketbatch',
    img: 'https://twitter.com/rocketbatch/photo',
  },
  facebook: {
    id: '@rocketbatch',
    img: 'https://twitter.com/rocketbatch/photo',
  },
};

const SEO = () => {
  return (
    <Helmet>
      <meta property="fb:app_id" content={SEO_DATA.facebook.id} />
      <meta property="og:title" content={SEO_DATA.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={SEO_DATA.url} />
      <meta property="og:image" content={SEO_DATA.facebook.img} />
      <meta property="og:description" content={SEO_DATA.description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@rocketbatch" />
      <meta name="twitter:site" content="@rocketbatch" />
      <meta name="twitter:title" content={SEO_DATA.title} />
      <meta name="twitter:description" content={SEO_DATA.description} />
      <meta name="twitter:domain" content={SEO_DATA.url} />
      <meta name="twitter:image:src" content={SEO_DATA.img} />

      <meta name="description" content={SEO_DATA.description} />
      <meta name="keywords" content={SEO_DATA.keywords.join(', ')} />
      <meta name="author" content={SEO_DATA.author} />

      <meta name="theme-color" content="#D4E1E5" />


      <title>{SEO_DATA.title}</title>
      <html lang="en" />
    </Helmet>
  );
};

export default SEO;
